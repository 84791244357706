import ActionTypes from "@/constants/action-types";
import FieldTypes from "@/constants/field-types";
import differenceInDays from "date-fns/differenceInDays";

export default {
  subfixDomain(state) {
    if (state.domain.trim() !== "") {
      const splittedDomain = state.domain.split(".");
      let noSubdomain = splittedDomain.slice(1);
      const joinedValue = noSubdomain.join(".");
      return "." + joinedValue;
    }
    return ".getbiew.com";
  },
  getPaymentCfgProp: state => (propKey = "") => {
    return state.paymentCfg[propKey] || "";
  },
  isAllPaymentCfg(state) {
    const {
      selectedCurrency,
      textButtonPay,
      stripeCfg: { publicKey, privateKey }
    } = state.paymentCfg;
    return [selectedCurrency, textButtonPay, publicKey, privateKey].some(
      value => value.length === 0
    );
  },
  isActivePaymentCfg: state => state.paymentCfg.active,
  getCurrenciesPaymentsAvailables(state) {
    return state.paymentCfg.currencies.map(currencyData => {
      return {
        key: `${currencyData.currency}`.toUpperCase(),
        value: `${currencyData.currency}`.toUpperCase()
      };
    });
  },
  getImageFields(state) {
    return state.fields.filter(el => el.type === FieldTypes.IMAGE);
  },
  isCheckoutEnabledInListView(state) {
    return state.listItemConfig.ctaField?.type === ActionTypes.NUM_CHECKOUT;
  },
  isCheckoutEnabledInDetailView(state) {
    return state.detailConfig.ctaField?.type === ActionTypes.NUM_CHECKOUT;
  },
  isShoppingCartEnabled(_, getters) {
    return (
      getters.isCheckoutEnabledInListView &&
      getters.isCheckoutEnabledInDetailView
    );
  },
  isPro(state, getters, _, rootGetters) {
    return state.isPro || getters.hasProTrial || rootGetters.isAgency;
  },
  isFirstWebsite(state, _, rootState) {
    const firstWebsite = rootState.firstWebsite;
    const isFirstWebsite = firstWebsite === state.websiteId;
    return isFirstWebsite;
  },
  daysSinceCreation(state) {
    const createdAt = new Date(state.createdAt?.seconds * 1000);
    const currentDate = Date.now();
    const daysDifference = 7 - differenceInDays(currentDate, createdAt);
    return daysDifference;
  },
  hasProTrial() {
    return false;
    // return getters.isFirstWebsite && getters.daysSinceCreation > 0;
  },
  websiteUrl(state) {
    const domain = state.domain;
    const isNotSubdomain =
      !domain.endsWith(".getbiew.com") && !domain.endsWith(".biew.site");
    const newSubdomain = domain.endsWith(".biew.site");
    const showCustomDomainField = domain && isNotSubdomain;
    const subdomain = state.subdomain;
    return showCustomDomainField
      ? `https://${domain}`
      : newSubdomain
      ? `https://${subdomain}.biew.site`
      : `https://${subdomain}.getbiew.com`;
  },
  showTrialBadge(state, getters, _, rootGetters) {
    return getters.hasProTrial && !rootGetters.isAgency && !state.isPro;
  }
};
