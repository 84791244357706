import axios from "axios";

const model = "licenses";

export default {
  availableLicenses() {
    return axios.get(`/api/${model}/available-licenses`);
  },
  assignWebsite(licenseId, websiteId) {
    return axios.patch(`/api/${model}/assign-website`, {
      licenseId,
      websiteId
    });
  }
};
