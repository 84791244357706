import { format } from "date-fns";
import { es, enUS } from "date-fns/locale";
import { capitalize } from "./string";
const DATE_FORMAT = "dd MMMM yyyy";
const currencyConfig = {
  mxn: ["es-MX", { style: "currency", currency: "MXN" }],
  usd: ["en-US", { style: "currency", currency: "USD" }]
};
const locales = {
  es: es,
  en: enUS
};
/**
 * Da formato a un objeto tipo "Website" para que retorne solo los valores requeridos para una funcionalidad empleada.
 * @param {*} website - Objeto de tipo "Website"
 * @returns Object - un objeto con diferentes propiedades
 */
export const formatWebsite = website => {
  const {
    _id,
    name,
    netlify,
    subdomain,
    subscription: {
      id: subscriptionId = "",
      periodEnd = 0,
      interval = "",
      currency = "",
      amount = 0,
      priceId = "",
      cancelAtPeriodEnd = 0
    },
    uid
  } = website;
  const localeLanguage = navigator.language.substring(0, 2);
  const formatedPeriodEnd = format(new Date(periodEnd * 1000), DATE_FORMAT, {
    locale: locales[localeLanguage] || enUS
  });
  const subscriptionCurrency = new Intl.NumberFormat(
    ...currencyConfig[currency]
  );
  let tmpAmount = amount.toString().split("");
  tmpAmount.splice(tmpAmount.length - 2, 0, ".");
  tmpAmount = tmpAmount.join("");
  return {
    id: _id,
    uid,
    subscriptionId,
    name,
    limitDate: formatedPeriodEnd,
    plan: capitalize(interval),
    totalAmount: subscriptionCurrency.format(parseFloat(tmpAmount)),
    currency: currency.toUpperCase(),
    priceId,
    cancelAtPeriodEnd,
    subdomain,
    netlify
  };
};

export const formatLicense = license => {
  const {
    _id,
    uid,
    websiteId = "",
    subscription: {
      id: subscriptionId = "",
      periodEnd = 0,
      interval = "",
      currency = "",
      amount = 0,
      priceId = "",
      cancelAtPeriodEnd = 0
    }
  } = license;
  const localeLanguage = navigator.language.substring(0, 2);
  const formatedPeriodEnd = format(new Date(periodEnd * 1000), DATE_FORMAT, {
    locale: locales[localeLanguage] || enUS
  });
  const subscriptionCurrency = new Intl.NumberFormat(
    ...currencyConfig[currency]
  );
  let tmpAmount = amount.toString().split("");
  tmpAmount.splice(tmpAmount.length - 2, 0, ".");
  tmpAmount = tmpAmount.join("");
  return {
    id: _id,
    uid,
    websiteId,
    subscriptionId,
    limitDate: formatedPeriodEnd,
    plan: capitalize(interval),
    totalAmount: subscriptionCurrency.format(parseFloat(tmpAmount)),
    currency: currency.toUpperCase(),
    priceId,
    cancelAtPeriodEnd
  };
};

/**
 *
 * @param {Date} date - Fecha a formatear
 * @returns
 */
export const formatToLocalDate = date => {
  if (!date)
    throw new Error("Argument is not a valid date: got " + typeof date);
  const localeLanguage = navigator.language.substring(0, 2);
  const formatedPeriodEnd = format(date, DATE_FORMAT, {
    locale: locales[localeLanguage] || enUS
  });
  return formatedPeriodEnd;
};

/**
 * Filtra un arreglo de objetos tipo "Website" que solo trae lo que tienen una suscripción Pro
 * @param {*} website
 * @returns  Objeto tipo "Website"
 */
export const filterByProSubscription = website => website.isPro === true;

/**
 * Filtra un arreglo de objetos tipo "Website" que solo trae los que no tienen una subscripción Pro
 * @param {*} website
 * @returns  Objeto tipo "Website"
 */
export const filterFreeWebsite = website =>
  !website?.subscription?.id && website.isPro === false;

/**
 * Función que valida si un valor es un objeto
 * @param {*} val - argumento a validar si se trata de un objeto
 * @returns boolean
 */
export const isObject = function isObject(val) {
  return Object.prototype.toString.call(val) === "[object Object]";
};
/**
 *
 * @param {*} val - si el argumento es un objeto, obtiene la cantidad de propiedades que tiene. Si el valor **no** es un objeto, regresa 0.
 * @returns
 */
export const objectSize = function objectSize(val) {
  return this.isObject(val) ? Object.keys(val).length : 0;
};

/**
 * Función que remueve propiedades de un objeto utilizando un arreglo de cadenas
 * con los nombre de las propiedades a elimnar.
 *
 * @param {Object} obj - Objeto del cual se van a copiar las propiedades a otro objeto
 * @param {Array} keys  - Arreglo que contiene el nombre de las propiedades a excluir
 * @returns Un objeto nuevo sin las propiedades especificadas. NOTA: Esta función regresa un nuevo objeto sin mutar el anterior.
 */
export const omitKeys = function omitKeys(obj = {}, keys = []) {
  let tmpObj = {};
  if (isObject(obj) && Array.isArray(keys)) {
    // const { query = {} } = this.$route;
    for (const k in obj) {
      if (!keys.includes(k)) {
        tmpObj[k] = obj[k];
      }
    }
  }
  return tmpObj;
};

/**
 * Formatea un número entero con un separador
 * @param {Integer} num - Número entero a formatear.
 * @param {*} options - Opciones del formateo
 * @param {*} options.position - Nùmero de la posición del separador, empezando de derecha a izquierda. Por defecto: "2"
 * @param {*} options.separator - Caracter que se utilizará para separa el número. Por fecto: "."
 * @returns String - El número formateado con un separador
 * @example
 * const options = {
 *    position: 3,
 *    separator: ","
 * }
 * console.log(formatWithSeparator(999999, options))
 * // logs 999,999
 */
export const formatWithSeparator = (num, options = {}) => {
  const { position = 2, separator = "." } = options;
  const splttedNum = String(num).split("");
  splttedNum.splice(splttedNum.length - position, 0, separator);
  const formatedNum = splttedNum.join("");
  return formatedNum;
};

export const seconds = (sec = 1) =>
  Number.isInteger(sec) && sec >= 0 ? sec * 1000 : 1000;

/**
 * Función que sirve como placeholder o función por defecto
 * @returns undefined
 */
export const noop = () => undefined;

/**
 * Función que sirve para registrar una función escucha de las notificaciones que llegan al cliente mientras la aplicación se encuentra en background
 * @param {*} cb - Callback que se ejecuta cuando llega una notificación al cliente mientras la aplicación está en background
 * @returns Función que sirve para eliminar la función que escucha el evento de notificaciones.
 * @example
 * const unsubscribe = onBackgroundMessage(function(payload){
 *  // código
 * });
 * unsubscribe() // <-- remueve el listener
 */
export const onBackgroundMessage = cb => {
  const messageListener = function(event) {
    if (event.data && event.data.type === "BIEW-APP-NOTIFICATION") {
      cb(event.data.payload);
    }
  };
  if ("serviceWorker" in navigator)
    navigator.serviceWorker.addEventListener("message", messageListener);
  return () => {
    navigator.serviceWorker.removeEventListener("message", messageListener);
  };
};

export default {
  formatWebsite,
  filterByProSubscription,
  formatLicense,
  filterFreeWebsite,
  formatWithSeparator,
  objectSize,
  isObject,
  omitKeys,
  formatToLocalDate,
  seconds,
  onBackgroundMessage,
  noop
};
